import React from 'react';

function Testimonials() {
  const img_1_src =
    'https://lando-assets-production.s3.us-west-2.amazonaws.com/landing_page/design_with_testimonials_2023/testimonials-1.png';
  const img_2_src =
    'https://lando-assets-production.s3.us-west-2.amazonaws.com/landing_page/design_with_testimonials_2023/testimonials-2.png';

  const text_copy = window.dataFromRuby.testimonials;

  return (
    <div className='ev-testimonials qa-testimonials'>
      <h1 className='ev-testimonials-header'>{text_copy.header}</h1>
      <p className='ev-testimonials-subheader'>{text_copy.subheader}</p>

      <section className='ev-testimonial-section'>
        <img src={img_1_src} alt='Testimonial portrait' />
        <div className='ev-testimonial-info-section ev-left'>
          <h2 className='ev-testimonials-header ev-testimonials-info-quote'>&quot;{text_copy.quote_1}&quot;</h2>
          <div className='ev-testimonial-info-elaboration'>{text_copy.elaboration_1}</div>
          <div className='ev-testimonial-info-citation'>{text_copy.name_1}</div>
        </div>
      </section>

      <section className='ev-testimonial-section ev-reversed'>
        <div className='ev-testimonial-info-section ev-right'>
          <h2 className='ev-testimonials-header ev-testimonials-info-quote'>&quot;{text_copy.quote_2}&quot;</h2>
          <div className='ev-testimonial-info-citation'>{text_copy.name_2}</div>
        </div>
        <img src={img_2_src} alt='Testimonial portrait' />
      </section>

      <section className='ev-testimonials-fine-print'>{text_copy.fine_print}</section>
    </div>
  );
}

export default Testimonials;
