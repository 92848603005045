import PropTypes from 'prop-types';
import React, { useState } from 'react';

import InputField from '../../../form_fields/input_field';
import RightArrow from '../../../shared/right_arrow';

import RestartTheCoverageCheckLink from './restart_the_coverage_check_link';

function NotCovered({ genericCoverageCheckId, onRestartCoverageCheck }) {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    $.ajax('/gcc_hr_contact_leads/', {
      method: 'POST',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      data: {
        gcc_hr_contact_lead: {
          email,
          generic_coverage_check_id: genericCoverageCheckId,
        },
      },
      success: () => {
        setSuccessfulSubmit(true);
      },
      error: (response) => {
        if (response.responseJSON) {
          setErrors(response.responseJSON.errors);
        }
      },
    });
  };

  return (
    <div className='gcc__results-container gcc__results-container--vertical gcc__results-container--no-navbar'>
      <div className='gcc__results-image'>
        <img
          role='presentation'
          width='255'
          alt='covered'
          src='https://lando-assets-production.s3-us-west-2.amazonaws.com/generic_coverage_check/not-covered.png'
        />
      </div>
      <div className='top'>
        <div className='gcc__results-heading' role='heading' aria-level='2'>
          Let’s see what we can do…
        </div>
        <div className='gcc__results-content'>
          Based on the information you entered, it looks like Omada may not be a covered health benefit for you, but we
          encourage you to go back and fill out the form again. Be sure to double check the name of your employer and/or
          health insurer.
        </div>
        <RestartTheCoverageCheckLink onRestartCoverageCheck={onRestartCoverageCheck} />
      </div>
      <div className='bottom'>
        <div className='gcc__results-content'>
          {successfulSubmit && (
            <div className='c-alert--success u-4mb--xl qa-success'>Thanks! We'll reach out soon.</div>
          )}
          If it's not clear that you’re covered, we’d love to reach out to your employer and work on getting Omada
          covered for you. Please share an email address for your Human Resources department below.
        </div>
        <form onSubmit={handleSubmit} className='qa-hr-email-form'>
          <InputField
            containerClasses='u-4mb--l'
            fieldName='email'
            type='email'
            label='Human Resources Email'
            placeholder='hr@mycompany.com'
            maxLength='100'
            value={email}
            errors={errors}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete='email'
          />
          <button
            className='c-btn--primary gcc__results-button qa-hr-email-button'
            type='submit'
            disabled={successfulSubmit || email.length === 0}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

NotCovered.propTypes = {
  genericCoverageCheckId: PropTypes.number.isRequired,
};

export default NotCovered;
