import { debounce } from 'lodash';
import { intersection } from 'lodash/array';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import Blob2 from '../../icons/blob2';
import { conditionCustomCompare } from '../../utils/possible_conditions';
import sliderSetters from '../../utils/slider_settings';
import windowIsMobileSize from '../../utils/windowIsMobileSize';
import ProgramInfoTile from '../program_info_tile';

const infoByCondition = {
  prevention: {
    title: 'Weight loss',
    informativeBlurb: 'Build healthy habits and lower your health risks through small but powerful changes.',
    image: 'omada-supported-conditions-prevention.png',
    imageDescription: 'TODO',
  },
  diabetes: {
    title: 'Diabetes',
    informativeBlurb: 'Keep glucose levels in check with new ways to eat well, move more, and manage stress.',
    image: 'omada-supported-conditions-diabetes.png',
    imageDescription: 'TODO',
  },
  hypertension: {
    title: 'High blood pressure',
    informativeBlurb: 'Lose weight, gain energy, and learn ways to lower high blood pressure outside of medication.',
    image: 'omada-supported-conditions-htn.png',
    imageDescription: 'TODO',
  },
  musculoskeletal: {
    title: 'Joint & muscle health',
    informativeBlurb: 'Treat and prevent aches and pains with physical therapy, on your time.',
    image: 'omada-supported-conditions-musculoskeletal.png',
    imageDescription: 'TODO',
  },
};

function createTileDataIcon(imageUrl, imageDescription) {
  return <img src={imageUrl} alt={imageDescription} />;
}

function CoveredPrograms() {
  const { possible_conditions_at_entry } = window.dataFromRuby;
  if (!possible_conditions_at_entry) return null;
  const [onMobileScreen, setOnMobileScreen] = useState(windowIsMobileSize(1024));
  const [nav1, setNav1] = useState(null);
  let slider1 = [];

  useEffect(() => {
    if (onMobileScreen) {
      setNav1(slider1);
    }
    window.addEventListener(
      'resize',
      debounce(() => setOnMobileScreen(windowIsMobileSize(1024)), 200),
    );
  }, [slider1]);

  const renderCoveredConditions = () => {
    const retrieveProgramInfo = (condition) => {
      if (condition === 'diabetes') {
        if (intersection(possible_conditions_at_entry, ['type1_diabetes', 'type2_diabetes']).length === 0) return null;
      } else if (!possible_conditions_at_entry.includes(condition)) return null;
      const programInfo = infoByCondition[condition];
      return {
        ...programInfo,
        imageUrl: `https://lando-assets-production.s3-us-west-2.amazonaws.com/landing_page/design_2021/${programInfo.image}`,
      };
    };

    if (onMobileScreen) {
      const renderInfoTile = (condition) => {
        const programInfo = retrieveProgramInfo(condition);
        if (!programInfo) return null;
        const { imageDescription, imageUrl, informativeBlurb, title } = programInfo;

        const tileData = {
          title,
          subtext: informativeBlurb,
          icon: () => createTileDataIcon(imageUrl, imageDescription),
        };
        return <ProgramInfoTile key={title} tileName={title} tileData={tileData} isMobile />;
      };

      const sliderCardSettings = sliderSetters({
        asNavFor: nav1,
        dotsClass: 'ev-slider-dots slick-dots',
        autoplay: false,
      });

      return (
        <div className='conditions-omada-supports__slider-container'>
          <Slider
            asNavFor={sliderCardSettings.asNavFor}
            autoplay={sliderCardSettings.autoplay}
            autoplaySpeed={sliderCardSettings.autoplaySpeed}
            infinite={sliderCardSettings.infinite}
            pauseOnFocus={sliderCardSettings.pauseOnFocus}
            pauseOnDotsHover={sliderCardSettings.pauseOnDotsHover}
            pauseOnHover={sliderCardSettings.pauseOnHover}
            ref={(slider) => {
              slider1 = slider;
            }}
          >
            {Object.keys(infoByCondition).map((condition) => renderInfoTile(condition))}
          </Slider>
        </div>
      );
    }

    return Object.keys(infoByCondition)
      .sort(conditionCustomCompare)
      .map((condition) => {
        const programInfo = retrieveProgramInfo(condition);
        if (!programInfo) return null;
        const { imageDescription, imageUrl, informativeBlurb, title } = programInfo;

        return (
          <div className={`covered-condition-info qa-${condition}-info`} key={condition}>
            <img src={imageUrl} alt={imageDescription} role='presentation' />
            <div role='heading' aria-level='3' className='u-4mt--s u-4mb--xxxs covered-condition-title'>
              {title}
            </div>
            <div className='covered-condition-subtitle'>{informativeBlurb}</div>
          </div>
        );
      });
  };

  return (
    <div className='t-bg--white conditions-omada-supports-section u-pos--r qa-conditions-supported'>
      <div className='covered-programs__blob'>
        <Blob2 />
      </div>
      <div className='conditions-omada-supports__body u-m--a u-text--c'>
        <div className='ev-what-is-omada__header u-m--a'>
          <h2 className='ev-what-is-omada__header-text u-4mb--l qa-section-title'>
            With Omada, there’s a program for you
          </h2>
          <div className='ev-conditions-omada-supports-subheader qa-section-subtitle'>
            Get ongoing support for your health, head-to-toe. Omada has programs available for:
          </div>
        </div>
        <div className='ev-covered-conditions-list covered-conditions-list u-flex qa-covered-programs'>
          {renderCoveredConditions()}
        </div>
      </div>
    </div>
  );
}

export default CoveredPrograms;
