import { debounce, map, filter, includes } from 'lodash';
import { flatten, intersection, uniq } from 'lodash/array';
import React, { useState, useEffect } from 'react';

import { combineDiabetesConditions, hasDiabetes, hasMskOnly, hasPreventionOnly } from '../../utils/possible_conditions';
import windowIsMobileSize from '../../utils/windowIsMobileSize';

function DeviceTrayHeader({ isPreventionOnly }) {
  const header = 'Track your progress with ease';
  const subHeaderClause =
    "You'll receive a welcome kit with easy-to-use smart devices based on your needs, at no cost to you.";
  const subHeaderRest = ' Shipped to your door and yours to keep.';
  const asterisk = '*';

  return (
    <>
      <h1 className='ev-devices-in-focus__header qa-device-at-no-cost-header'>{header}</h1>
      <p className='ev-devices-in-focus__body qa-device-at-no-cost-body'>
        {subHeaderClause}
        <sup data-testid='device-copy-sup'>{!isPreventionOnly && asterisk}</sup>
        {subHeaderRest}
      </p>
    </>
  );
}

function DevicesInFocus() {
  const {
    background_image_url,
    devices_in_focus: deviceCopy,
    show_cgm_device,
    possible_conditions_at_entry,
  } = window.dataFromRuby;
  const isPreventionOnly = hasPreventionOnly(possible_conditions_at_entry);
  const hasDiabetesButNoCGM = hasDiabetes(possible_conditions_at_entry) && !show_cgm_device;
  const shouldLoadComponent = !hasMskOnly(possible_conditions_at_entry);
  if (!shouldLoadComponent) return null;

  const devicesByCondition = {
    diabetes: ['continuous_glucose_monitor', 'blood_glucose_meter', 'strips', 'scale'],
    hypertension: ['blood_pressure_monitor', 'scale'],
    musculoskeletal: ['exercise_kit'],
    prevention: ['scale'],
  };

  const [onMobileScreen, setOnMobileScreen] = useState(windowIsMobileSize(1024));
  useEffect(() => {
    window.addEventListener(
      'resize',
      debounce(() => setOnMobileScreen(windowIsMobileSize()), 200),
    );
  }, []);

  const retrieveDeviceCards = () => {
    const idealOrder = [
      'continuous_glucose_monitor',
      'blood_glucose_meter',
      'strips',
      'blood_pressure_monitor',
      'scale',
      'exercise_kit',
    ];

    let orderedDevices;

    if (isPreventionOnly) {
      orderedDevices = ['scale', 'omada_app'];
    } else {
      const conditions = combineDiabetesConditions(possible_conditions_at_entry);
      let uniqueDevices = flatten(map(uniq(conditions), (condition) => devicesByCondition[condition]));

      if (hasDiabetesButNoCGM) {
        uniqueDevices = filter(uniqueDevices, (device) => device !== 'continuous_glucose_monitor');
      }

      orderedDevices = filter(idealOrder, (device) => includes(uniqueDevices, device));
    }

    return map(orderedDevices, (device) => deviceCopy[device]);
  };

  const cardDetails = retrieveDeviceCards();
  const cards = cardDetails.map((card) => (
    <li key={card.header} className='device-card'>
      <img
        className='ev-device-card__image qa-device-image'
        src={`https://lando-assets-production.s3.us-west-2.amazonaws.com/landing_page/${card.image}`}
        alt={card.header}
      />
      <div className='ev-device-card__content'>
        <p className='ev-device-card__header' data-testid='device-header'>
          <strong dangerouslySetInnerHTML={{ __html: card.header }} />
        </p>
        <p className='ev-device-card__body' dangerouslySetInnerHTML={{ __html: card.subheader }} />
      </div>
    </li>
  ));

  return (
    <div className='ev-devices-in-focus qa-devices'>
      <DeviceTrayHeader isPreventionOnly={isPreventionOnly} />
      <img
        className='ev-devices-in-focus__background-image'
        src={`https://lando-assets-production.s3.us-west-2.amazonaws.com/landing_page/${background_image_url}`}
        alt='large orange O in the background'
      />
      <div className='ev-devices-in-focus__details'>
        <ul className='device-cards-container'>{cards}</ul>
      </div>
    </div>
  );
}

export default DevicesInFocus;
