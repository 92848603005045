import React from 'react';

function ComingSoon() {
  return (
    <div className='gcc__results-container gcc__results-container--horizontal'>
      <div className='left'>
        <p className='gcc__results-heading'>Your program is coming soon!</p>
        <p className='gcc__results-content'>
          Thank you for your interest in Omada! We're accepting applications starting soon - stay tuned for more
          information from your sponsor.
        </p>
        <p className='gcc__results-content'>
          Questions? Email <span className='gcc__results-strong-content'>support@omadahealth.com</span>
        </p>
      </div>
      <div className='right'>
        <img
          role='presentation'
          alt='covered'
          src='https://lando-assets-production.s3-us-west-2.amazonaws.com/generic_coverage_check/maybe-covered.png'
        />
      </div>
    </div>
  );
}

export default ComingSoon;
