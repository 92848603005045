import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

import Checkmark from '../../icons/checkmark';
import sliderSetters from '../../utils/slider_settings';

function DevicesMobileSlider({ programInfoToPresent }) {
  const sliderSettings = sliderSetters({ centerMode: false, dotsClass: 'ev-slider-dots slick-dots' });
  return (
    <div className='ev-device-at-no-cost-slider'>
      <Slider
        arrows={sliderSettings.arrows}
        autoplay={sliderSettings.autoplay}
        autoplaySpeed={sliderSettings.autoplaySpeed}
        centerMode={sliderSettings.centerMode}
        centerPadding={sliderSettings.centerPadding}
        dots={sliderSettings.dots}
        dotsClass={sliderSettings.dotsClass}
        focusOnSelect={sliderSettings.focusOnSelect}
        infinite={sliderSettings.infinite}
        nextArrow={sliderSettings.nextArrow}
        prevArrow={sliderSettings.prevArrow}
        slidesToScroll={sliderSettings.slidesToScroll}
        slidesToShow={sliderSettings.slidesToShow}
      >
        {programInfoToPresent.map((programInfo) => {
          const { bodyText, bulletTextList, condition, headerText, img, imageAlt, title } = programInfo;
          return (
            <div key={title}>
              <div>
                <div className={`ev-device-at-no-cost-slider__content qa-device-condition-${condition}`}>
                  <div className='ev-device-at-no-cost-slider__body u-4pl--s u-4pr--s u-4pt--xxl'>
                    <img
                      alt={imageAlt}
                      className='ev-device-img'
                      src={`https://lando-assets-production.s3-us-west-2.amazonaws.com/landing_page/${img}`}
                      role='presentation'
                    />
                    <h1 className='header'>{headerText || title}</h1>
                    <p className='ev-devices-description-body'>{bodyText}</p>
                    <ul className='list'>
                      {bulletTextList.map((text) => (
                        <li className='bullet' key={text}>
                          <Checkmark />
                          <div className='bullet-text'>{text}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

const { arrayOf, shape, string } = PropTypes;

DevicesMobileSlider.propTypes = {
  programInfoToPresent: arrayOf(
    shape({
      title: string.isRequired,
      className: string,
      img: string.isRequired,
      imageAlt: string.isRequired,
      bodyText: string.isRequired,
      bulletTextList: arrayOf(string).isRequired,
    }),
  ).isRequired,
};

export default DevicesMobileSlider;
